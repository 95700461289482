import { mapGetters } from 'vuex';

import { DOWNLOAD_PAI_DATA, DOWNLOAD_PDF } from '@/store/actions.type';
import { START_LOADING, FINISH_LOADING } from '@/store/mutations.type';

import { APP_URL } from '@/common/config.js';

import html2canvas from 'html2canvas';

import EventBus from '@/eventBus';
import download from '@/helpers/download';

export default {
	data() {
		return {
			interval: null
		};
	},
	computed: {
		...mapGetters(['paiFilters'])
	},
	mounted() {
		EventBus.$on('IFRAME_LOADED', this._handleIframeLoaded);
	},
	methods: {
		async submitPaiDownloadModalForm(queryParams, form) {
			if (form.format === 'csv') {
				this._downloadCSV(form.title);
				return;
			}

			this.$store.commit(START_LOADING);
			const previewURL = this._generatePreviewURL(queryParams, form);

			localStorage.setItem('download', form.format);
			this._takeSnapshotOfURL(previewURL);
			this._closeModal();
		},
		setPaiRouterParams(filters) {
			const params = this._generatePaiRouterParams(filters);

			this.$router
				.replace({ name: 'policy-advocacy-index', query: params })
				.catch(err => err);

			return params;
		},
		clearParams(params) {
			const cleanParams = { ...params };
			cleanParams.allAfrica = cleanParams.countries.includes('all-africa');
			cleanParams.countries = cleanParams.countries.filter(
				country => country !== 'all-africa'
			);

			return cleanParams;
		},
		// Private functions
		async _downloadPNG(chartImage, fileName) {
			download(chartImage, fileName);
			localStorage.removeItem('download');
		},
		async _downloadCSV(title) {
			const params = {
				...this.setPaiRouterParams(this.paiFilters),
				...{ filename: title ? title : new Date().getTime() }
			};

			const {
				data: { link, filename }
			} = await this.$store.dispatch(
				DOWNLOAD_PAI_DATA,
				this.clearParams(params)
			);
			download(link, filename);
			this.modal = null;
		},
		async _downloadPDF(chartImage, fileName) {
			const form = new FormData();
			const response = await fetch(chartImage);
			const blob = await response.blob();
			const file = new File([blob], fileName, { type: 'image/png' });
			form.append('file', file, `${fileName}.png`);
			form.append('filename', fileName);

			const {
				data: { link, filename }
			} = await this.$store.dispatch(DOWNLOAD_PDF, form);

			window.open(link);

			localStorage.removeItem('download');
		},
		async _handleIframeLoaded(body, title) {
			const fileName = title ? title : new Date().getTime();
			const downloadFormat = localStorage.getItem('download');

			const canvas = await html2canvas(body);
			const chartImage = canvas.toDataURL('image/png');

			this.$destroy();

			if (downloadFormat.includes('png')) {
				this._downloadPNG(chartImage, fileName);
				return;
			}

			this._downloadPDF(chartImage, fileName);
		},
		_generatePaiRouterParams(filters) {
			return {
				countries: filters.countries.map(c => c.id),
				indicator: filters.indicator
			};
		},
		_takeSnapshotOfURL(url) {
			this._removeIframe();

			const iframe = document.createElement('iframe');
			iframe.id = 'chart-preview';
			iframe.width = 1440;
			iframe.height = 900;
			iframe.src = url;
			iframe.style.cssText =
				'position: absolute; opacity:0; z-index: -9999;bottom: 0';
			document.body.appendChild(iframe);
		},
		_removeIframe() {
			const iframeExists = document.querySelector('#chart-preview');

			if (iframeExists) {
				iframeExists.remove();
			}
		},
		_generatePreviewURL(queryParams, form) {
			const params = {
				...this._generatePaiRouterParams(this.paiFilters),
				...queryParams
			};

			let previewURLParams = Object.keys(params)
				.map(key => {
					let param = key + '=' + encodeURI(params[key]);
					if (key === 'countries') {
						param = params[key].map(key => `countries=${key}`).join('&');
					}
					return param;
				})
				.join('&');

			previewURLParams += '&' + form.colors.map(key => `colors=${key.replace('#', '')}`).join('&');

			return `${APP_URL}/policy-advocacy-index/preview?${previewURLParams}`;
		},
		_closeModal() {
			this.interval = setInterval(() => {
				const download = localStorage.getItem('download');

				if (!download) {
					this.modal = null;
					clearInterval(this.interval);
					this.$store.commit(FINISH_LOADING);
				}
			}, 500);
		}
	}
};
