<template>
	<div class="chart-section__bar chart-section__bar--regular bar--overflow">
		<div class="y-axis-bar">
			<div class="p p--small">High</div>
			<div class="p p--small">Low</div>
		</div>
		<canvas id="chart" class="bar--square"/>
		<div class="square"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import imageManager from '@/mixins/image-manager';

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.plugins.unregister(ChartDataLabels);

export default {
	mixins: [imageManager],
	name: 'BarChart',
	data() {
		return {
			chart: null
		};
	},
	computed: {
		...mapState({
			data: store => store.pai.data
		}),
		color() {
			const paramsColors = this.$route.query.colors;

			if (paramsColors) return `#${this.$route.query.colors}`;

			return '#87bbaf';
		}
	},
	watch: {
		data: {
			handler: 'setChart',
			immediate: true,
			deep: true
		}
	},
	methods: {
		setChart() {
			this.$nextTick(async () => {
				if (this.chart != null) {
					this.chart.destroy();
				}

				if (!this.data) return;

				var ctx = document.getElementById('chart').getContext('2d');
				ctx.height = 556;

				let labels = this.data.map(singleData => singleData.label);
				let data = this.data.map(singleData => singleData.value);


				const images = await Promise.all(
					this.data.map(async singleData => {
						let image = {
							width: 70,
							height: 70
						};

						image.src = await this.toDataURL(
							require(`@/assets/imgs/flags/${singleData.slug}.png`)
						);

						return image;
					})
				);

				for (let i = 0; i < data.length; i++) {
					if (!data[i]) {
						labels.splice(i, 1);
						data.splice(i, 1);
						images.splice(i, 1);
					}
				}

				const _this = this;

				this.chart = new Chart(ctx, {
					type: 'bar',
					plugins: [ChartDataLabels],
					data: {
						labels,
						datasets: [
							{
								data,
								backgroundColor: this.color
							}
						]
					},
					options: {
						maintainAspectRatio: false,
						responsive: true,
						layout: {
							padding: {
								top: 50
							}
						},
						legend: {
							display: false
						},
						animation: {
							onComplete: function() {
								this.options.animation.onComplete = null;
								_this.$emit('onLoad');
							}
						},
						scales: {
							scaleShowGridLines: false,
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
										display: false,
										stepSize: 0.5
									},
									gridLines: {
										drawBorder: true,
										stepSize: 0.5,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: 'transparent',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},
									scaleLabel: {
										display: true,
										labelString: '',
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							],
							xAxes: [
								{
									ticks: {
										beginAtZero: true,
										// display: false,
										stepSize: 0.05,
										padding: 20,
										fontSize: 14,
										fontFamily: 'Muli'
									},
									gridLines: {
										drawBorder: true,
										stepSize: 0.5,
										drawTicks: false,
										zeroLineWidth: 2,
										// color: 'rgba(151,151,151,0.31)',
										color: 'transparent',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									}
								}
							]
						},
						plugins: {
							datalabels: {
								anchor: 'end',
								align: 'start',
								padding: 10,
								font: {
									size: 20,
									weight: 700,
									family: 'Muli'
								},

								color: '#ffffff'
							},
							labels: {
								render: 'image',
								anchor: 'top',
								align: 'start',
								textMargin: -20,
								images
							}
						}
					}
				});
			});
		}
	}
};
</script>

<style scoped>
.y-axis-bar {
	position: absolute;
	left: 10px;
	height: calc(100% - 100px);
	width: 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	top: 50px;
}


.bar--square {
	z-index: 3;
}

.square {
	background-size: 20px 20px;
	background-image: linear-gradient(
			to right,
			rgba(151, 151, 151, 0.3) 1px,
			transparent 1px
	),
	linear-gradient(to bottom, rgba(151, 151, 151, 0.3) 1px, transparent 1px);
	max-width: 750px;
	position: absolute;
	width: 100%;
	left: 55px;
	height: 388px;
	top: 50px;
	z-index: 1;
}

.bar--overflow {
	overflow-x: hidden;
}

@media only screen and (max-width: 991px) {
	.square {
		height: 369px;
	}
}

</style>
