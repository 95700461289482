<template>
	<div ref="paiPreview" style="padding: 5% 20%">
		<VHeading level="3" v-if="title">
			{{ title }}
		</VHeading>

		<div v-html="explanation.content" v-if="explanation"></div>
		<div style="width: 800px">
			<component :is="activeChart" @onLoad="handleChartLoaded" />
		</div>

		<div class="tab-section__content__footer__card footer" v-if="keys">
			<VHeading level="6" weight="semibold" classes="hide show@tablet title" style="margin-left: -58px;">
				Legend
			</VHeading>

			<div class="tab-section__content__footer__countries">
				<div
					class="tab-section__content__footer__countries__item"
					v-for="key in keys"
					:key="key.slug"
				>
					<div
						class="flag"
						:style="key.color ? { backgroundColor: key.color } : {}"
					>
						<img
							:src="require(`@/assets/imgs/${key.image}`)"
							:alt="key.name"
							v-if="key.image"
						/>
					</div>
					<VText size="smaller" weight="semibold">
						{{ key.name }}
					</VText>
				</div>
			</div>
		</div>
		<div
			class="tab-section__content__footer__card footer"
			style="margin-top: 30px"
			v-if="url"
		>
			<VHeading level="6" weight="semibold" classes="hide show@tablet title"  style="margin-left: -36px;">
				URL:
			</VHeading>
			<VLink>{{ url }}</VLink>
		</div>
	</div>
</template>

// TODO: here we have a lot of duplicated code from PAI index.vue - it needs to
clean up!
<script>
import { mapGetters, mapState } from 'vuex';

import {
	FETCH_COUNTRIES,
	FETCH_PAI_DATA,
	FETCH_TOOLTIP
} from '../store/actions.type';

import { APP_URL } from '@/common/config.js';

import pai from '@/mixins/pai';
import EventBus from '@/eventBus';
import BarChart from '@/pages/LandingPage/sections/PolicyAdvocacyIndex/BarChart';
import BubbleChart from '@/pages/LandingPage/sections/PolicyAdvocacyIndex/BubbleChart';

export default {
	name: 'PAIPreview',
	mixins: [pai],
	components: {
		BarChart,
		BubbleChart
	},
	data() {
		return {
			explanation: null
		};
	},
	computed: {
		...mapGetters(['paiFilters']),
		...mapState({
			countries: state => state.app.countries
		}),
		title() {
			return this.$route.query.title;
		},
		activeChart() {
			if (!this.paiFilters.indicator) return null;

			return this.paiFilters.indicator === 'composite-score'
				? 'BubbleChart'
				: 'BarChart';
		},
		url() {
			const countriesParam = this.params.countries
				.map(key => 'countries=' + key)
				.join('&');

			return this.$route.query.url === 'true'
				? `${APP_URL}/policy-advocacy-index?indicator=${this.params.indicator}&${countriesParam}`
				: null;
		},
		keys() {
			return this.$route.query.legend
				? this.paiFilters.countries.map(country => {
						return {
							id: country.id,
							name: country.name,
							image: `flags/${country.slug}.png`
						};
				  })
				: null;
		},
		params() {
			return {
				countries: this.$route.query.countries,
				indicator: this.$route.query.indicator
			};
		}
	},
	async created() {
		localStorage.setItem('iframeLoading', true);
		await this.fetchCountries();
		await this.setData();
		await this.fetchData();
	},
	methods: {
		async fetchCountries() {
			await this.$store.dispatch(FETCH_COUNTRIES, {
				is_active: 'true',
				graph: 'pai',
				indicator: this.paiFilters.indicator
			});
		},
		async fetchData() {
			await this.$store.dispatch(FETCH_PAI_DATA, this.clearParams(this.params));
		},
		async setData() {
			const queryParams = this.$route.query;

			if (queryParams.indicator)
				this.paiFilters.indicator = queryParams.indicator;

			if (queryParams.explanation === 'true')
				this.explanation = await this.$store.dispatch(
					FETCH_TOOLTIP,
					'pai-info'
				);

			if (queryParams.allAfrica)
				this.paiFilters.countries.push({
					id: 'all-africa',
					name: 'All Africa',
					slug: 'all-africa'
				});

			if (!queryParams.countries) return;

			const countries =
				queryParams.countries && typeof queryParams.countries == 'object'
					? queryParams.countries
					: [queryParams.countries];

			this.paiFilters.countries = this.countries.filter(country =>
				countries.includes(String(country.id))
			);

			const allAfrica = countries.includes('all-africa');

			if (allAfrica)
				this.paiFilters.countries.push({
					id: 'all-africa',
					name: 'All Africa',
					slug: 'all-africa'
				});
		},
		handleChartLoaded() {
			EventBus.$emit('IFRAME_LOADED', this.$refs.paiPreview, this.title);
		}
	}
};
</script>
