<template>
	<div class="chart-section__bubble">
		<div class="y-axis-bubble">
			<div class="p p--small">High</div>
			<div class="p p--small">Low</div>
		</div>
		<canvas id="chart" v-click-outside="removeTooltip" @click="removeTooltip"></canvas>
		<div class="x-axis-bubble">
			<div class="p p--small">Low</div>
			<div class="p p--small">High</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import countries from '@/mixins/countries';

import Chart from 'chart.js';
import 'chart.js/dist/Chart.min.css';

export default {
	name: 'BubbleChart',
	mixins: [countries],
	data() {
		return {
			chart: null,
			activeData: null
		};
	},
	computed: {
		...mapState({
			data: store => store.pai.data
		})
	},
	watch: {
		data: {
			handler: 'setChart',
			immediate: true,
			deep: true
		}
	},
	methods: {
		setChart() {
			this.$nextTick(() => {
				this.removeTooltip();

				if (this.chart != null) {
					this.chart.destroy();
				}

				if (!this.data) return;

				var ctx = document.getElementById('chart').getContext('2d');
				ctx.height = 600;

				const data = this.data.map(singleData => {
					return {
						x: singleData.engage,
						y: singleData.capacity
					};
				});

				const pointStyles = this.data.map(singleData => {
					if (!singleData.slug) return;
					const image = new Image();

					image.src = require(`@/assets/imgs/flags/${singleData.slug}.png`);

					image.width = 70;
					image.height = 70;
					return image;
				});

				const _this = this;
				const customTooltip = this.generateTooltip();

				this.chart = new Chart(ctx, {
					type: 'scatter',
					plugins: {
						afterUpdate: chart => {
							chart.getDatasetMeta(0).data.forEach((d, i) => (d._model.pointStyle = pointStyles[i]));
						}
					},
					data: {
						datasets: [
							{
								data,
								pointRadius: 1
							}
						]
					},
					options: {
						responsive: true,
						events: ['click', 'mouseout'],
						maintainAspectRatio: false,
						legend: {
							display: false
						},
						animation: {
							onComplete: function() {
								this.options.animation.onComplete = null;
								_this.$emit('onLoad');
							}
						},
						scales: {
							scaleShowGridLines: false,
							yAxes: [
								{
									ticks: {
										beginAtZero: true,
										display: false,
										stepSize: 2,
										suggestedMin: 0,
										suggestedMax: 100
									},
									gridLines: {
										drawBorder: true,
										stepSize: 0.5,
										drawTicks: false,
										zeroLineWidth: 2,
										color: 'rgba(151,151,151,0.31)',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},
									scaleLabel: {
										display: true,
										labelString: 'Enabling capacity',
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							],
							xAxes: [
								{
									ticks: {
										beginAtZero: true,
										display: false,
										stepSize: 2,
										suggestedMin: 0,
										suggestedMax: 100
									},
									gridLines: {
										drawBorder: true,
										stepSize: 0.5,
										drawTicks: false,
										zeroLineWidth: 2,
										color: 'rgba(151,151,151,0.31)',
										zeroLineColor: '#979797',
										zeroLineBorderDashOffset: 0.5
									},

									scaleLabel: {
										display: true,
										labelString: 'Opportunity to engage',
										color: '#979797',
										fontSize: '14',
										fontStyle: 'bold',
										defaultFontStyle: 'Muli',
										padding: 20
									}
								}
							]
						},
						tooltips: {
							intersect: false,
							enabled: false,
							mode: 'index',
							position: 'average',
							custom: customTooltip,
							callbacks: {
								label: function(tooltipItem, data) {
									var label = data.datasets[tooltipItem.datasetIndex].label || '';

									if (label) {
										label += '';
									}

									return '<sup>$</sup>';
								}
							}
						}
					}
				});
			});
		},
		removeTooltipEventListener() {
			const engagementWindowsEl = document.querySelector('#engagement-windows');
			const dataExplorerEl = document.querySelector('#data-explorer');

			if (engagementWindowsEl) {
				engagementWindowsEl.removeEventListener('click', () => {});
			}

			if (dataExplorerEl) {
				dataExplorerEl.removeEventListener('click', () => {});
			}
		},
		setTooltipEventListener() {
			const country = this.getCountry(this.activeData.slug);

			if (!country) return;

			document.querySelector('#engagement-windows').addEventListener('click', () => {
				this.$router.push({
					name: 'engaging-with-government',
					query: { country: country.name }
				});
			});

			document.querySelector('#data-explorer').addEventListener('click', () => {
				this.$router.push({
					name: 'policy-advocacy-data-explorer',
					query: { countries: [String(country.id)] }
				});
			});
		},
		generateTooltip() {
			const _this = this;
			return function(tooltip) {
				_this.activeData = null;
				_this.removeTooltipEventListener();

				if (!tooltip.dataPoints) return;

				_this.activeData = _this.data[tooltip.dataPoints[0].index];

				_this.removeTooltip();

				const buttonsHTML =
					_this.activeData.slug !== 'all-africa'
						? `<button id="engagement-windows" class="button button--small button__primary-dark">
                                Engagement windows
                            </button>
                            <button id="data-explorer" class="button button--small button__primary-dark">
                                ${_this.activeData.label} data explorer
                            </button>`
						: '';

				let tooltipEl = document.createElement('div');
				tooltipEl.id = 'chartjs-tooltip';
				tooltipEl.innerHTML = `
                        <div>
                            <div class="chart-section__popup__inner">
                                <div class="chart-section__popup__number">
                                    ${_this.activeData.score}
                                </div>
                                <div class="">
                                    <div class="p p--small p--extra-bold">${_this.activeData.label}</div>
                                    <div class="p p--small p--semibold">Composite score</div>
                                </div>
                            </div>
							${buttonsHTML}
                        </div>
                    `;

				this._chart.canvas.parentNode.appendChild(tooltipEl);

				// Hide if no tooltip
				if (tooltip.opacity === 0) {
					tooltipEl.style.opacity = 0;
					return;
				}

				// Set caret Position
				tooltipEl.classList.remove('above', 'below', 'no-transform');

				if (tooltip.yAlign) {
					tooltipEl.classList.add(tooltip.yAlign);
				} else {
					tooltipEl.classList.add('no-transform');
				}

				function getBody(bodyItem) {
					return bodyItem.lines;
				}

				var positionY = this._chart.canvas.offsetTop;
				var positionX = this._chart.canvas.offsetLeft;

				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.fontFamily = 'Muli';
				tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
				tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';

				_this.setTooltipEventListener();
			};
		},
		removeTooltip() {
			let tooltipEl = document.getElementById('chartjs-tooltip');

			if (tooltipEl) {
				tooltipEl.remove();
			}
		}
	}
};
</script>

<style>
#chartjs-tooltip {
	pointer-events: all !important;
	min-height: auto;
}

.y-axis-bubble {
	position: absolute;
	left: 10px;
	height: calc(100% - 64px);
	width: 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.x-axis-bubble {
	position: absolute;
	left: 79px;
	height: 40px;
	width: calc(100% - 79px);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	bottom: 0;
}

@media only screen and (max-width: 480px) {
	.x-axis {
		bottom: -17px;
	}
}
</style>
